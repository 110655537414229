import React from 'react';

import Bugsnag from '@bugsnag/js';
import { Button, ButtonGroup, Text } from '@chakra-ui/react';
import { Alert } from '@firehydrant/design-system';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import { trackEvent } from 'helpers/pawPrints';
import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';

export function LocalError({ error, resetErrorBoundary }) {
  const { account, currentOrganization, currentUser } = useUserContext();
  // This will be used to validate whether this component is rendering without Bugsnag
  // so we can start getting data on how many FE errors we are dropping
  if (!Bugsnag.isStarted()) {
    trackEvent({
      name: 'Unhandled Frontend Bugsnag Error',
      params: {
        error: error.message,
        stack: error.stack,
      },
    });
  }

  return (
    <Alert variant="alert">
      <Text as="p" fontWeight="bold">
        Oops! There was an unexpected error.
      </Text>
      {error && <Text as="p">{error.message}</Text>}
      <ButtonGroup>
        <Button colorScheme="red" onClick={resetErrorBoundary}>
          Try Again
        </Button>
        <Button
          variant="outline"
          as="a"
          onClick={() => {
            navigator.clipboard.writeText(`
===== Support Information =====
User Agent: ${window.navigator.userAgent}
Browser: ${window.navigator.appName} - Version: ${window.navigator.appVersion}
Operating System: ${window.navigator.platform}
Screen Resolution: ${window.screen.width}x${window.screen.height}
Account: ${account.id} - ${account.name}
Organization: ${currentOrganization.node.id}
User: ${currentUser?.id} - ${currentUser?.email}
Current URL: ${window.location.href}
Timestamp: ${new Date().toLocaleString()}
Bugsnag Initialized: ${Bugsnag.isStarted()}
================================

===== Error Data =====
Error Message: ${error.message}
Stack Trace: ${error.stack}
================================
`);

            window.open(
              'https://firehydrant.zendesk.com/hc/en-us/requests/new?ticket_form_id=360001339611',
              '_blank',
            );
          }}
          rightIcon={<FiExternalLink />}
        >
          Copy support information and submit ticket
        </Button>
      </ButtonGroup>
    </Alert>
  );
}

LocalError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};
