import React, { useState } from 'react';

import { Box, Button, ButtonGroup, Card, Flex, Text } from '@chakra-ui/react';
import { Icon } from '@firehydrant/design-system';
import { useFormikContext } from 'formik';

import FormV2 from 'components/common/Form/FormV2/FormV2';

import { celSnippets } from '../signalsFormHelpers';
import CELExamplesDrawer from './CELExamplesDrawer';
import { CELExpressionBuilder } from './CELExpressionBuilder';

export default function FormikCELInput({ annotationsAndTags, ...props }) {
  const [infoDrawerIsOpen, setInfoDrawerIsOpen] = useState(false);
  const formik = useFormikContext();

  const snippets = celSnippets(annotationsAndTags);

  return (
    <>
      <Flex direction="column">
        <Card px="2" pt="2" pb="3" position="relative" border="none">
          <Box
            position="absolute"
            top="16px"
            left="16px"
            zIndex="10"
            backgroundColor="gray.50"
            py="0.5"
            px="2"
            display="flex"
            alignItems="center"
            gap="1"
            borderRadius="4px"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.25 12.25L9.21859 9.21859M9.21859 9.21859C10.0103 8.42687 10.5 7.33312 10.5 6.125C10.5 3.70875 8.54125 1.75 6.125 1.75C3.70875 1.75 1.75 3.70875 1.75 6.125C1.75 8.54125 3.70875 10.5 6.125 10.5C7.33312 10.5 8.42687 10.0103 9.21859 9.21859Z"
                stroke="#605E75"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <Text mb="0" fontSize="6" fontFamily="mono">
              CEL
            </Text>
          </Box>
          <Flex direction="column" width="100%">
            <FormV2.Textarea
              sx={{ pl: '75px', fontFamily: 'mono' }}
              autoSize
              hoverText="Need help with cel?"
              data-testid="cel-input"
              {...props}
            />
            <Flex justifyContent="space-between">
              <ButtonGroup>
                <CELExpressionBuilder
                  snippets={snippets[1]}
                  formikProps={formik}
                  defaultSnippet={snippets[1].snippet}
                  buttonText="Level"
                />
                <CELExpressionBuilder
                  snippets={snippets[2]}
                  formikProps={formik}
                  defaultSnippet={snippets[2].snippet}
                  buttonText="Summary"
                />
                <CELExpressionBuilder
                  snippets={snippets[3]}
                  formikProps={formik}
                  defaultSnippet={snippets[3].snippet}
                  buttonText="Body"
                />
                <CELExpressionBuilder
                  snippets={snippets[4]}
                  formikProps={formik}
                  buttonText="Annotations"
                />
                <CELExpressionBuilder
                  snippets={snippets[5]}
                  formikProps={formik}
                  buttonText="Tags"
                />
                <Button
                  variant="link"
                  mr="3"
                  fontSize="6"
                  leftIcon={<Icon name="info" boxSize="4" />}
                  onClick={() => setInfoDrawerIsOpen(true)}
                >
                  Need some CEL examples?
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>
        </Card>{' '}
      </Flex>
      <CELExamplesDrawer
        isOpen={infoDrawerIsOpen}
        onClose={() => setInfoDrawerIsOpen(false)}
      />
    </>
  );
}
