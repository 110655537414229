import React from 'react';

import {
  Box,
  Flex,
  Stack,
  Text,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { Field, useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

export const FormikRadio = ({
  label,
  helpText,
  dataTestId,
  variant = 'outline',
  styleProps = {},
  ...props
}) => {
  const field = useField(props)[0];
  const { getInputProps, getRadioProps } = useRadio({ ...props });

  const input = getInputProps();
  const isSelected = field.value === input.value;
  const checkbox = getRadioProps();

  const isGhost = variant === 'ghost';

  return (
    <Box as="label" mb="0" data-testid={dataTestId} w="100%">
      <input {...input} />
      <Flex
        {...checkbox}
        cursor={input.disabled ? 'not-allowed' : 'pointer'}
        borderWidth="1px"
        borderRadius="md"
        bg={isSelected && !isGhost ? 'primary.50' : 'white'}
        border={isGhost ? '0px' : '1px'}
        borderColor={isSelected ? 'primary.500' : 'gray.100'}
        p={isGhost ? '1' : '3'}
        alignItems="center"
        {...styleProps}
      >
        <Flex
          w="16px"
          h="16px"
          borderRadius="lg"
          border="2px"
          borderColor={isSelected ? 'primary.500' : 'gray.200'}
          bg={isSelected ? 'primary.500' : 'none'}
          mr="3"
          alignItems="center"
          justifyContent="center"
        >
          {isSelected && <Box w="6px" h="6px" bg="white" borderRadius="md" />}
        </Flex>

        <Flex flexDirection="column" w="calc(100% - 32px)">
          <Text
            mb="0"
            fontSize="5"
            fontWeight={isSelected ? '600' : input.disabled ? '300' : '500'}
            p="0"
            color={
              isSelected
                ? isGhost
                  ? 'gray.700'
                  : 'primary.500'
                : input.disabled
                ? 'gray.300'
                : 'gray.600'
            }
          >
            {label}
          </Text>
          {helpText && (
            <Text
              mb="0"
              color={
                isSelected
                  ? 'gray.500'
                  : input.disabled
                  ? 'gray.200'
                  : 'gray.400'
              }
              fontSize="6"
            >
              {helpText}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export const FormikRadioGroupV2 = ({
  name,
  children,
  onChange = () => {},
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: (v) => {
      onChange?.(v);
      setFieldValue(name, v);
    },
  });
  const group = getRootProps();
  return (
    <Field name={name}>
      {(formikProps) => {
        return (
          <Stack {...group} {...props}>
            {children({
              getRadioProps,
              formikProps,
            })}
          </Stack>
        );
      }}
    </Field>
  );
};

FormikRadioGroupV2.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      helpText: PropTypes.string,
    }),
  ),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};
